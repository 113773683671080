@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');

html {
    scroll-behavior: smooth;
    /* overflow: hidden; */
}

body {
    font-family: 'Poppins', sans-serif;
}

#navbar {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

#navbar .navbar-brand img {
    max-width: 70%;
    height: auto;
}

#navbar .navbar-nav .nav-link {
    position: relative;
}

#navbar .navbar-nav .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: transparent;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s;
}

#navbar .navbar-nav .nav-link:hover::after {
    background-color: rgb(179, 0, 0); /* Color when hovering */
}

a.nav-link.active::after {
    background-color: rgb(179, 0, 0); /* Color for the active link */
}

a.nav-link.active {
    color: rgb(179, 0, 0) !important;
}

.landing {
    font-family: 'Poppins', sans-serif;
    /* background-color: wheat; */
    min-height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing h1 {
    font-weight: 700;
}

.form-container {
    font-family: 'Poppins', sans-serif;
    background-color: wheat;
    padding: 40px 0;
}

.form {
    max-width: 400px;
    margin: auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.form p {
    text-align: center;
    color: #6c757d;
    margin-bottom: 30px;
}

.form-group-label {
    font-size: 16px; /* Adjust the font size as needed */
    font-weight: bold; /* Optionally adjust the font weight */
}

.checkbox {
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
    cursor: pointer;
}

/* @media (max-width: 768px) {
    .form {
        max-width: 100%;
        padding: 15px;
    }
} */

.form-control {
    height: 30px; /* Adjust the height as needed */
    font-size: 14px; /* Adjust the font size as needed */
}

.form label span {
    color: red;
}

#formCheckbox label p {
    /* Your CSS styling for the <p> tag goes here */
    color: blue;
    font-size: 16px;
    /* Add other styles as needed */
}

.form .btn-submit {
    border-radius: 1.875rem !important;
}

.contact {
    font-family: 'Poppins', sans-serif !important;
}

.custom-input {
    height: 35px; /* Adjust the height as needed */
}

.button-link {
    color: black;
    text-decoration: none;
}

.footer {
    font-family: 'Cormorant Garamond', serif;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 500;
}

.footer p {
    text-align: right;
    font-weight: 700;
}

input[type='checkbox'] {
    transform: scale(2);
}

/* Responsive Styles */

/* @media (max-width: 768px) {
    .form-control {
        width: 100%;
    }
} */

.custom-readonly {
    pointer-events: none;
    /* opacity: 0.5; */
    cursor: not-allowed;
}

select {
    height: 30px; /* Adjust the height as needed */
}
